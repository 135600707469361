import request from '@/utils/request'


// 查询VIP会员列表
export function listMember(query) {
  return request({
    url: '/user/vip-member/list',
    method: 'get',
    params: query
  })
}

// 查询VIP会员分页
export function pageMember(query) {
  return request({
    url: '/user/vip-member/page',
    method: 'get',
    params: query
  })
}

// 查询VIP会员详细
export function getMember(data) {
  return request({
    url: '/user/vip-member/detail',
    method: 'get',
    params: data
  })
}

// 新增VIP会员
export function addMember(data) {
  return request({
    url: '/user/vip-member/add',
    method: 'post',
    data: data
  })
}

// 修改VIP会员
export function updateMember(data) {
  return request({
    url: '/user/vip-member/edit',
    method: 'post',
    data: data
  })
}

// 删除VIP会员
export function delMember(data) {
  return request({
    url: '/user/vip-member/delete',
    method: 'post',
    data: data
  })
}
